<template>
    <div class="main">
        <section class="video-container">
            <div class="banner-overlay"></div>
            <video class="video" autoplay muted loop>
                <source src="./../assets/My-Virtual-Convention by SYNTHES'3D.mp4" type="video/mp4">
            </video>
            <div class="video-info">
                <img src="./../assets/logo-video.png" alt="logo video" class="video-logo" />
                <h1>A virtual event solution & experience</h1>
            </div>
        </section>
        <!-- EVENT -->
        <section class="event">
            <div class="sep sep-top"></div>
            <p>REGROUPEZ LES ACTEURS DE VOTRE MARCHE, VOS PROSPECTS, CLIENTS ET COLLABORATEURS SANS LES FAIRE SE DEPLACER.</p>
            <h2 class="gradiant-text">UN ÉVÉNEMENT DIGITAL, POURQUOI ?</h2>
        </section>
        <!--video demo -->
        <section class="video-demo">
            <div class="sep sep-top"></div>
            <div class="sep sep-bottom"></div>
            <div class="row row-step">
                <div class="col25">
                    <div class="step-item step1">
                        <img src="./../assets/step1.png" alt="step 1" />
                        <div class="step-number">1</div>
                        <p>Informez,<br /> Présentez, Expliquez</p>
                    </div>
                </div>
                <div class="col25">
                    <div class="step-item step2">
                        <img src="./../assets/step2.png" alt="step 2" />
                        <div class="step-number">2</div>
                        <p>Entrez en relation,<br /> Echangez & Tchattez</p>
                    </div>
                </div>
                <div class="col25">
                    <div class="step-item step3">
                        <img src="./../assets/step3.png" alt="step 3" />
                        <div class="step-number">3</div>
                        <p>Collectez<br /> des leads qualifiés et<br /> transformez-les !</p>
                    </div>
                </div>
                <div class="col25">
                    <div class="step-item step4">
                        <img src="./../assets/step4.png" alt="step 4" />
                        <div class="step-number">4</div>
                        <p>Formez via<br />des conférences<br />et des tables rondes</p>
                    </div>
                </div>
            </div>
            <div class="row video-vimeo">
                <div class="col40">
                    <h2>Cette vidéo présente un salon virtuel organisé sur notre plateforme de salon virtuel</h2>
                    <router-link :to="{name : 'Demo'}" class="btn btn-gradiant btn-demo">VOUS VOULEZ VOIR UNE DÉMO ?</router-link>
                </div>
                <div class="col60">
                    <vueVimeoPlayer 
                        ref="player"
                        :video-url="videoURL"                        
                        :player-height="height"
                        >
                    </vueVimeoPlayer>
                </div>
            </div>
        </section>
        <!-- EVENT 2-->
        <section class="event2">
            <div class="sep sep-bottom"></div>
            <!-- row 1 -->
            <div class="row">
                <div class="col50">
                    <div class="event-item">
                        <img src="./../assets/event1.png" alt="event1" />
                        <h3>100% accessible</h3>
                        <p>Accéder au salon virtuel via votre ordinateur, tablette ou smartphone.</p>
                    </div>
                </div>
                <div class="col50">
                    <div class="event-item">
                        <img src="./../assets/event2.png" alt="event2" />
                        <h3>100% virtuel ou hybride</h3>
                        <p>Un congrès peut désormais être 100% digital ou "phygital" avec un équivalent en présentiel qui existe en parallèle.</p>
                    </div>
                </div>
            </div>
            <!-- row 2 -->
            <div class="row">
                <div class="col50">
                    <div class="event-item">
                        <img src="./../assets/event3.png" alt="event3" />
                        <h3>Ponctuel ou Permanent</h3>
                        <p>Les salons virtuels peuvent être ponctuels sur quelques heures ou quelques jours comme ouverts 7j/7, 24h/24 et 365j / an depuis n'importe où !</p>
                    </div>
                </div>
                <div class="col50">
                    <div class="event-item">
                        <img src="./../assets/event4.png" alt="event4" />
                        <h3>Tchat, Live & Rencontres</h3>
                        <p>Les visiteurs et les exposants peuvent échanger ensemble facilement via messagerie, tchat & rencontres en visio.</p>
                    </div>
                </div>
            </div>
            <!-- row 3 -->
            <div class="row">
                <div class="col50">
                    <div class="event-item">
                        <img src="./../assets/event5.png" alt="event5" />
                        <h3>Haute personnalisation graphique</h3>
                        <p>Logo, couleurs des interfaces, hall d'exposition 3D, stands 3D : tout est personnalisable et sur mesure !</p>
                    </div>
                </div>
                <div class="col50">
                    <div class="event-item">
                        <img src="./../assets/event6.png" alt="event6" />
                        <h3>PDF, Vidéos, Photos, Produits & Services</h3>
                        <p>Les exposants peuvent ajouter des documents, des photos, des vidéos ainsi que lister les produits et services qu'ils proposent de manière totalement autonome et en temps réel.</p>
                    </div>
                </div>
            </div>
            <!-- row 4 -->
            <div class="row">
                <div class="col50">
                    <div class="event-item">
                        <img src="./../assets/event7.png" alt="event7" />
                        <h3>Statistiques & KPIs</h3>
                        <p>Obtenez des statistiques et des informations précises sur les visiteurs et calculer votre ROI.</p>
                    </div>
                </div>
                <div class="col50">
                    <div class="event-item">
                        <img src="./../assets/event8.png" alt="event8" />
                        <h3>Modules de recherche</h3>
                        <p>Les visiteurs accèdent aux informations rapidement via des filtres de recherche détaillés.</p>
                    </div>
                </div>
            </div>
            <!-- row 5 -->
            <div class="row">
                <div class="col50">
                    <div class="event-item">
                        <img src="./../assets/event9.png" alt="event9" />
                        <h3>Aucun téléchargement ni aucun plugin requis</h3>
                        <p>Nul besoin de télécharger un logiciel ou une application. Notre plateforme est accessible via nos plateformes Web.</p>
                    </div>
                </div>
            </div>
        </section>
        <!-- Applications -->
        <section class="applications ">
            <h2 class="gradiant-text">LES DIFFERENTES APPLICATIONS POSSIBLES<br /> POUR LES ÉVÉNEMENTS VIRTUELS</h2>
            <div class="row">
                <div class="col50">
                    <div class="applications-item">
                        <h3>Salons professionnels &<br /> Foires industrielles</h3>
                        <ul>
                            <li>Salon dédié aux produits et services</li>
                            <li>Salon dédié à une industrie spécifique</li>
                            <li>Salon Grand Public</li>
                            <li>Foire BtoC</li>
                        </ul>
                    </div>
                </div>
                <div class="col50">
                    <div class="applications-item">
                        <h3>Conférence & Convention<br /> scientifique virtuelle</h3>
                        <ul>
                            <li>Congrès virtuel accompagné de conférences scientifiques</li>
                            <li>Conférence médicale</li>
                            <li>Symposium médical</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col50">
                    <div class="applications-item">
                        <h3>Événement Corporate</h3>
                        <ul>
                            <li>Exposition interne de fournisseurs</li>
                            <li>Salon de présentation des divisions et des business units</li>
                            <li>Présentation d’entreprise</li>
                            <li>Événement pour les collaborateurs</li>
                            <li>Événement de lancement</li>
                            <li>Portes ouvertes d'entreprise</li>
                        </ul>
                    </div>
                </div>
                <div class="col50">
                    <div class="applications-item">
                        <h3>Salon de l’Emploi</h3>
                        <ul>
                            <li>Événements de recrutement virtuels</li>
                            <li>Expositions et conférences pour les Cadres</li>
                            <li>Salons de l'emploi des Grandes Ecoles et des Universités</li>
                            <li>Journées d'orientation</li>
                            <li>Portes ouvertes</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col50">
                    <div class="applications-item">
                        <h3>Campus virtuel</h3>
                        <ul>
                            <li>Académie virtuelle</li>
                            <li>e-Learning</li>
                            <li>Formation</li>
                        </ul>
                    </div>
                </div>
                <div class="col50">
                    <div class="applications-item">
                        <h3>Événements Marketing & Presse</h3>
                        <ul>
                            <li>Conférence de presse virtuelle</li>
                            <li>Événement pour la génération de leads marketing</li>
                            <li>Lancement de produit</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <!--Demo -->
        <section class="demo-container">
            <div class="sep sep-top"></div>
            <div class="sep sep-bottom"></div>
            <h2>VOUS VOULEZ UNE DÉMO EN LIVE ?</h2>
            <div class="row">
                <div class="col40">
                    <h3>Merci de compléter ce formulaire</h3>
                    <p>L'un des membres de notre équipe vous contactera très prochainement pour planifier une démonstration live de notre solution technique !</p>
                </div>
                <div class="col60">
                    <Form></Form>
                </div>
            </div>
        </section>
    </div>
</template>


<script>
import Form from './../components/Form'
import { vueVimeoPlayer } from 'vue-vimeo-player'

export default {
    name : 'Home',
    data() {
        return {
            videoURL: 'https://vimeo.com/463788127/abcc604d0f',
            videoID : '463788127',
            height: 400
        }
    },
    components : {
        Form,
        vueVimeoPlayer
    }
}
</script>

<style scoped lang="scss">

    section {
        position: relative;
    }

    .video-container {
        min-height: calc(100vh - 100px);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
    }

    .banner-overlay {
         background:rgba(0,0,0,0.5);
    }

    .video {
        position: absolute;
        z-index: -1;       
        bottom:0;     
        right:0;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
    }

    .video-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    }

    .video-info img {
        max-width: 90%;
    }

    .video-info h1 {
        font-weight:400;
        text-shadow: 1px 1px 5px #000;
    }

    /* EVENEMENT */
    .event {
        padding:100px 0;
        display: flex;
        flex-direction: column;
        align-items:center;
    }

    .event p {
        font-size:22px;
        font-weight:700;
        text-align: center;
        max-width: 1200px;
    }

    .event .sep {
        background-image: url('./../assets/border-top-right-gradiant.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    /* VIDEO DEMO */

    .video-demo{
        background-image: url('./../assets/bg-stands2.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding:100px 0 200px 0;
        display: flex;
        flex-direction: column;
        align-items:center;
        position: relative;
    }

    .video-demo .sep-top {
        background-image: url('./../assets/border-top-left-white.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .video-demo .sep-bottom {
        background-image: url('./../assets/border-bottom-right-grey.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .video-demo .row {
        width: $boxWidth;
        max-width: 96%; 
    }

    .row-step {
        margin-top: -100px;;
    }

    .video-demo .col25 {
        display: flex;
        justify-content: center;
    }

    .step-item {
        width:220px;
        height:200px;
        background: #fff;
        border-radius:20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        box-shadow: 0 10px 20px 5px rgba(0,0,0,0.5);
        padding:20px;
        position: relative;
        margin-bottom: 40px;;
        position: relative;
    }

    .step-item img {
        width: 90%;
        position: absolute;
        top: -160px;
    }

    .step-item p {
        font-size:20px;
        text-align: center;
        line-height: 25px;
        height:75px;
    }

    .step-number {
        font-size:25px;
        color:$violet;
        font-weight:700;
    }

    .step2 .step-number {
        color:#7578ab;
    }

    .step3 .step-number {
        color:#6494c9;
    }

    .step4 .step-number {
        color:#4598cf;
    }

    .video-vimeo h2 {
        color:#fff;
    }

    .video-vimeo p {
        font-size:18px;
        color:#fff;
        line-height: 25px;
    }

    .video-vimeo {
        position: relative;
        top: 100px;
        align-items: center;
    }

    .video-vimeo .col60 {
        display: flex;
        justify-content: center;
    }

    .video-vimeo h2 {
        text-align:left;
    }

    /* EVENEMENT 2 */
    .event2 {
        padding:100px 0;
        display: flex;
        flex-direction: column;
        align-items:center;
        background:$backgroundGray;
    }

    .event2 .sep {
        background-image: url('./../assets/border-bottom-left-white.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        transform: rotate(180deg);
    }

    .event2 .row {
        width: $boxWidth;
        max-width: 96%;
        justify-content: center;
        margin:20px 0;
    }

    .event-item {
        max-width:500px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin:0 auto;
    }

    .event-item h3 {
        margin:0;
        color:$violet;
        text-align:center;
        font-weight:700;
        font-size:25px;
        letter-spacing: 1px;
    }

    .event-item p {
        margin-top:10px;
        font-size:18px;
        text-align: center;
        line-height: 25px;
    }

    .event-item img {
        width:125px;
        height:125px;
    }

    .btn-demo {
         box-shadow: -410px 0 205px -205px $blue inset; 
    }

    .row.video-vimeo h2 {
        margin-bottom:40px
    } 

    
    /* APPLICATIONS */
    .applications {
        padding:100px 0;
        display: flex;
        flex-direction: column;
        align-items:center;
    }

    .applications .row{
        width: $boxWidth;
        max-width: 96%;
        align-items: center;
    }

    .applications h2 {
        margin-bottom:80px;
    }

    .applications-item {
        max-width : 525px;
        margin:0 auto;
    }

    .applications-item ul li {
        list-style: none;
        position:relative;
        margin-bottom:8px;
        font-size:18px;
        color:$mainColor;
    }

    .applications-item ul li:before {
        content:"";
        position: absolute;
        top : 5px;
        left:-20px;
        background:$violet;
        border-radius:50%;
        width:7px;
        height:7px;        
    }

    .applications-item h3 {
        font-size:25px;
        color: $violet;
        font-weight:700;
        letter-spacing: 1px;
    }

    /* DEMO */
    .demo-container {
        padding:100px 0;
        display: flex;
        flex-direction: column;
        align-items:center;
        background-image: url('./../assets/bg-stands.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .demo-container .sep-top {
        background-image: url('./../assets/border-top-left-white.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .demo-container .sep-bottom {
        background-image: url('./../assets/border-bottom-right-white.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .demo-container .row{
        width: $boxWidth;
        max-width: 96%;
        align-items: center;
        margin-top: 40px;
    }
    

    .demo-container h2, .demo-container h3, .demo-container p {
        color:#fff;
    }

    .demo-container h3 {
        font-size:25px;
        font-weight: 400;
    }

    @media screen and (max-width: 1024px) {
        .event2 .row, .applications .row {
            flex-direction: column;
        }

        .event2 .row{
            margin:0;
        }

        .event2 .col50 {
            margin:20px 0;
        }

        .col50 {
            width:98%;
        }

        .video-vimeo, .demo-container .row {
            flex-direction: column;
        }

        .video-vimeo .col40, .video-vimeo .col60, .demo-container .col40, .demo-container .col60 {
            width: 98%;
        }

        .video-vimeo h2, .demo-container h3 {
            text-align: center;
        }

        .video-vimeo p, .demo-container p {
            text-align: center;
            margin-bottom: 40px;
        }

        .step-item {
            margin-bottom: 120px;
        }

        .video-vimeo {
            margin-top: -120px;
        }

        .video-vimeo .col40  {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .video-vimeo .col60 {
            margin-top: 40px;
        }
    }

    @media screen and (max-width: 950px) {

        .col25 {
            width:48%;
        }

        .row-step {
            flex-wrap: wrap;
        }
    }

    @media screen and (max-width: 768px) {
        .row {
            flex-direction: column;
        }

        .col40, .col60 {
            width: 100%;
        }

        .col25 {
            width:98%;
        }
        
        .demo-container h2 {
            max-width: 96%;
            margin:0 2%;
        }

        .video {
            display: none;
        }

        .video-container {
            background-image: url('./../assets/bg-avantages.jpg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }
    }

    @media screen and (max-width: 480px) {

        .btn-demo {
            width:220px;
            box-shadow: -220px 0 110px -110px #189cd8 inset;
            text-align: center;
            font-size: 15px;
        }
    }

    @media (min-aspect-ratio: 16/9) {
        .video {
            width:100%;
            height: auto;
        }
    }
    @media (max-aspect-ratio: 16/9) {
        .video { 
            width:auto;
            height: 100%;
        }
    }
</style>